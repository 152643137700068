<template>
    <div class="container-fluid p-0">
        <ProfileComponent :languageId="props.languageId" :data="aboutSectionData" />
        <hr class="m-0">
        <ExperienceComponent :languageId="props.languageId" :data="experienceSectionData" />
        <hr class="m-0">
        <EducationComponent :languageId="props.languageId" :data="educationSectionData" />
        <hr class="m-0">
        <CertificationComponent :languageId="props.languageId" :data="certificationSectionData" />
        <hr class="m-0">
        <SkillComponent :languageId="props.languageId" :data="skillSectionData" />
    </div>
</template>

<script setup>

    import { ABOUT_SECTION, EXPERIENCE_SECTION, EDUCATION_SECTION, CERTIFICATIONS_SECTION, SKILLS_SECTION } from '../data/resume'
    import commonProps from '../shared/shared-props'
    import ProfileComponent from './ProfileComponent.vue'
    import ExperienceComponent from './ExperienceComponent.vue'
    import EducationComponent from './EducationComponent.vue'
    import CertificationComponent from './CertificationComponent.vue'
    import SkillComponent from './SkillComponent.vue'
    import { defineProps } from 'vue'

    const props = defineProps({
        ...commonProps
    })

    const aboutSectionData = ABOUT_SECTION
    const experienceSectionData = EXPERIENCE_SECTION
    const educationSectionData = EDUCATION_SECTION
    const certificationSectionData = CERTIFICATIONS_SECTION
    const skillSectionData = SKILLS_SECTION

</script>