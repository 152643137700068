<template>
    <h2 class="mb-5 resume-section-title" v-html="localizedTitle"></h2>
</template>

<script setup>

    import commonProps from "../shared/shared-props"
    import { getLocalizedString } from '../static/utilities'
    import { computed, defineProps } from 'vue'

    const props = defineProps({
        ...commonProps
        , title: {
            type: Object,
            required: true
        }
    })

    const localizedTitle = computed(() => getLocalizedString(props.title, props.languageId))

</script>
