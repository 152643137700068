<template>
    <section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="skills">
        <div class="w-100">
            <ResumeSectionTitleComponent :languageId="props.languageId" :title="props.data.title" />

            <div class="subheading mb-3">{{ programmingSkills.title }}</div>
            <ul class="list-inline dev-icons">
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fab', 'html5']" />
                </li>
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fab', 'css3-alt']" />
                </li>
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fab', 'js-square']" />
                </li>
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fab', 'node-js']" />
                </li>
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fab', 'git-square']" />
                </li>
            </ul>

            <div class="container-fluid">
                <div class="row mb-5">
                    <div v-for="(matrix, index) in programmingSkills.matrixItems" :key="index" class="col-12 col-md-6">
                        <ul class="fa-ul my-0">
                            <li v-for="(item, index) in matrix" :key="index">
                                <span class="fa-li"><font-awesome-icon :icon="['fas', 'check']" /></span>   
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div class="subheading mb-3">{{ webSkills.title }}</div>
            <ul class="list-inline dev-icons">
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fab', 'chrome']" />
                </li>
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fab', 'edge']" />
                </li>
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fab', 'safari']" />
                </li>
            </ul>

            <div class="container-fluid">
                <div class="row mb-5">
                    <div v-for="(matrix, index) in webSkills.matrixItems" :key="index" class="col-12 col-md-6">
                        <ul class="fa-ul my-0">
                            <li v-for="(item, index) in matrix" :key="index">
                                <span class="fa-li"><font-awesome-icon :icon="['fas', 'check']" /></span>
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="subheading mb-3">{{ databaseSkills.title }}</div>
            <ul class="list-inline dev-icons">
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fas', 'server']" />
                </li>
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fas', 'database']" />
                </li>
            </ul>

            <div class="container-fluid">
                <div class="row mb-5">
                    <div v-for="(matrix, index) in databaseSkills.matrixItems" :key="index" class="col-12 col-md-6">
                        <ul class="fa-ul my-0">
                            <li v-for="(item, index) in matrix" :key="index">
                                <span class="fa-li"><font-awesome-icon :icon="['fas', 'check']" /></span>   
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="subheading mb-3">{{ osSkills.title }}</div>
            <ul class="list-inline dev-icons">
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fab', 'windows']" />
                </li>
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fab', 'ubuntu']" />
                </li>
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fab', 'apple']" />
                </li>
                <li class="list-inline-item">
                    <font-awesome-icon :icon="['fab', 'android']" />
                </li>
            </ul>

            <div class="container-fluid">
                <div class="row mb-5">
                    <div v-for="(matrix, index) in osSkills.matrixItems" :key="index" class="col-12 col-md-6">
                        <ul class="fa-ul my-0">
                            <li v-for="(item, index) in matrix" :key="index">
                                <span class="fa-li"><font-awesome-icon :icon="['fas', 'check']" /></span>                              
                                {{ item }}                   
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>

<script setup>

import commonProps from '../shared/shared-props'
import { getLocalizedString, splitArray } from '../static/utilities'
import ResumeSectionTitleComponent from './ResumeSectionTitleComponent.vue'
import { computed, defineProps } from 'vue'

const props = defineProps({
    ...commonProps
    , data: {
        type: Object,
        required: true
    }
})

const programmingSkills = computed(() => generateSkillViewModel(props.data.programmingSkill, props.languageId, 8))

const webSkills = computed(() => generateSkillViewModel(props.data.webSkill, props.languageId, 5))

const databaseSkills = computed(() => generateSkillViewModel(props.data.databaseSkill, props.languageId, 6))

const osSkills = computed(() => generateSkillViewModel(props.data.osSkill, props.languageId, 8))

const generateSkillViewModel = (skill, language, breakCount) => {
    return {
        title: getLocalizedString(skill.title, language),
        matrixItems: splitArray(skill.items, breakCount)
    }
}

</script>