import $ from "jquery";
import 'bootstrap'
import "jquery.easing";

/**
 * Binds events of the side nav menu navigation elements. 
 */
export function bindNavigationEvents() {

    // Smooth scrolling using jQuery easing.
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').on('click', function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {

            let target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            if (target.length) {
                $('html, body').animate({
                    scrollTop: (target.offset().top)
                }, 1000, "easeInOutExpo");
                return false;
            }
        }
    });

    // Closes responsive menu when a scroll trigger link is clicked.
    $('.js-scroll-trigger').on('click', function () {
        closeNavbarCollapse();
    });

    // Open/Close language menu.
    $('.nav-language-item').on('click', function () {
        $('.language-collapse').collapse('toggle');
    });

    // Activate scrollspy to add active class to navbar items on scroll.
    // Dispose de previous spy because of the updated side nav.
    $('body').scrollspy('dispose')
        .scrollspy({
            target: '#sideNav'
        });
}

/**
 * Close all responsive menu.
 */
export function closeNavbarCollapse() {
    $('.navbar-collapse, .language-collapse').collapse('hide');
}
