import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faLanguage, faDatabase, faServer, faCheck} from '@fortawesome/free-solid-svg-icons'
//import { faCheck } from '@fortawesome/free-regular-svg-icons'
import { faAndroid, faApple, faUbuntu, faWindows, faLinkedinIn, faHtml5, faCss3Alt, 
         faJsSquare, faNodeJs, faGitSquare, faChrome, faEdge, faSafari } from '@fortawesome/free-brands-svg-icons'
import './styles/site.scss'

// Add all Font Awesome icons into the page
library.add(faLanguage, faWindows, faUbuntu, faApple, faAndroid, faLinkedinIn, 
            faHtml5, faCss3Alt, faJsSquare, faNodeJs, faGitSquare, faChrome, 
            faEdge, faSafari, faDatabase, faServer, faCheck)

createApp(App).component("font-awesome-icon", FontAwesomeIcon)
              .mount('#app')
