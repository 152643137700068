/**
 * Available languages in the app.
 */
export const AVAILABLE_LANGUAGES = [
    {
        id : 'fr',
        description : 'Français'
    },
    {
        id : 'en',
        description : 'English'
    }      
];

/**
 * Items displayed in the side nav menu.
 */
export const NAVIGATION_ITEMS = [
    {
        en : "About", 
        fr : "À propos",
        sectionId : "about"        
    },
    {
        en : "Experience", 
        fr : "Expériences",
        sectionId : "experience"           
    },
    {
        en : "Education", 
        fr : "Formation",
        sectionId : "education"          
    },
    {
        en : "Certifications", 
        fr : "Certifications",
        sectionId : "certifications"          
    },	
    {
        en : "Skills", 
        fr : "Compétences",
        sectionId : "skills"          
    }      
];