<template>
    <section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="about">
        <div class="w-100">
            <h2 class="mb-0">Vincent
                <span class="text-primary">Gagnon</span>
            </h2>
            <div class="subheading mb-5"></div>
            <p class="lead mb-5 text-justify">
                {{ localizedText }}
            </p>
            <div class="social-icons">
            </div>
        </div>
    </section>
</template>

<script setup>

    import commonProps from "../shared/shared-props"
    import { getLocalizedString } from '../static/utilities'
    import { computed, defineProps } from 'vue'

    const props = defineProps({
        ...commonProps
        , data: {
            type: Object,
            required: true
        }
    })

    const localizedText = computed(() => getLocalizedString(props.data.text, props.languageId));

</script>