<template>
    <LinkedInComponent />
    <SideNavComponent :languageId="selectedLanguageId" @languageChanged="handleLanguageChange" />
    <ResumeComponent :languageId="selectedLanguageId" />
</template>

<script setup>

    import { onMounted, onUpdated, nextTick, ref } from 'vue'
    import * as nav from './static/sidenav-action'
    import { getPreferredLanguage, setPreferredLanguage } from './static/language'
    import { setHtmlLanguage } from './static/utilities'
    import SideNavComponent from './sidenav/SideNavComponent.vue'
    import ResumeComponent from './resume/ResumeComponent.vue'
    import LinkedInComponent from './social/LinkedInComponent.vue'

    const selectedLanguageId = ref(getPreferredLanguage());

    const handleLanguageChange = (id) => {
        selectedLanguageId.value = id;
        setHtmlLanguage(id);
        setPreferredLanguage(id);
    }

    const rebindEvents = () => {
        nextTick(() => {
            nav.bindNavigationEvents();
            nav.closeNavbarCollapse();
        });
    }

    onMounted(() => {
        rebindEvents();
        setHtmlLanguage(selectedLanguageId.value)
    })

    onUpdated(() => {
        rebindEvents();
    })

</script>