/**
 * About section content.
 */
export const ABOUT_SECTION = {
    text : {
        en : "Experienced in analyzing and designing solutions mostly for the web with the most popular technologies and those a little less common. Trained to working with teams using Agile and Rapid Prototyping methodologies. Qualified in project management and in allocating resources to their realization.", 
        fr : "Expérimenté dans l’analyse et la conception de solutions pour la plupart à destination du web avec les technologies les plus populaires et celles qui le sont un peu moins. Habitué de travailler avec des équipes mettant en pratique les méthodologies agiles et de prototypage. Qualifié dans la gestion de projets et des ressources allouées à leur réalisation."          
    }
};

/**
 * Experience section content.
 */
export const EXPERIENCE_SECTION = {
	title : {
		en : "Experi&shy;ence",
		fr : "Expéri&shy;ences"
	},
	experiences : [
		{
			title : {
				en : "IT Analyst",
				fr : "Analyste informatique"
			},
			place : {
				en : "Marguerite-Bourgeoys School Services Centre",
				fr : "Centre de services scolaire Marguerite-Bourgeoys"
			},
			range : {
				en : "April 2018 - Present",
				fr : "Avril 2018 – À ce jour"			
			},
			tasks : [
				{
					en : "Systems integration.",
					fr : "Intégration de systèmes."				
				},
				{
					en : "Automated build and deployment of Web applications using Azure DevOps.",
					fr : "Automatisation de la génération et du déploiement d'applications Web à l'aide d'Azure DevOps."				
				},				
				{
					en : "Coordinated technological choices.",
					fr : "Conduite des choix technologiques."				
				},
				{
					en : "System architecture design on Windows, Linux and Microsoft Azure Cloud platforms.",
					fr : "Conception de l’architecture des systèmes basés sur Windows, Linux et Microsoft Azure Cloud."				
				},
				{
					en : "ASP.NET MVC and Web Forms solution developments hosted on Microsoft IIS platform.",
					fr : "Développement de solutions Web ASP.NET MVC et Web Forms hébergées sur la plateforme Microsoft IIS."				
				},
				{
					en : "SQL Server databases analysis and design.",
					fr : "Analyse et conception de bases de données SQL Server."				
				},
				{
					en : "SOAP and REST Web APIs developments using ASP.NET Web API 2 and WCF technologies.",
					fr : "Développement d’APIs Web SOAP et REST utilisant les technologies ASP.NET Web API 2 et WCF."				
				},
				{
					en : "Writing of unit tests and integration tests using NUnit, MsTest and Cypress testing tools.",
					fr : "Écriture des tests unitaires et tests d'intégrations à l’aide des outils de tests NUnit, MsTest et Cypress."
				},
				{
					en : "Managed Team Foundation Servers and DevOps Servers including repositories.",
					fr : "Gestion des serveurs Team Foundation Server et DevOps comprenant les dépôts de code."				
				},					
				{
					en : "Proofs of Concept design.",
					fr : "Cadrer et concevoir des preuves de concept."				
				}			
			]
		},		
		{
			title : {
				en : "Software Developer - Principal Class",
				fr : "Développeur classe principale"
			},
			place : {
				en : "Marguerite-Bourgeoys School Services Centre",
				fr : "Centre de services scolaire Marguerite-Bourgeoys"
			},
			range : {
				en : "March 2016 – April 2018",
				fr : "Mars 2016 – Avril 2018"			
			},
			tasks : [
				{
					en : "C# and Visual Basic.NET solution developments and maintenance.",
					fr : "Développement et maintenance de solutions applicatives C# et Visual Basic.NET."				
				},
				{
					en : "Designing and deploying ASP.Net Web Forms applications hosted on Microsoft IIS platform.",
					fr : "Conception et déploiement d'applications Web ASP.NET Web Forms hébergées sur la plateforme Microsoft IIS."				
				},				
				{
					en : "Developed transact-SQL (T-SQL) scripts and queries on SQL Server databases.",
					fr : "Élaboration de requêtes et de scripts Transact-SQL (T-SQL) sur des bases de données SQL Server."				
				},
				{
					en : "Writing of unit tests using NUnit testing tool.",
					fr : "Écriture des tests unitaires à l’aide de l’outil de tests NUnit."				
				},
				{
					en : "Produced technical documentations for multiple systems.",
					fr : "Rédaction de la documentation technique pour différents systèmes."				
				},
				{
					en : "iOS application development using Swift programming language.",
					fr : "Développement en Swift d'applications mobiles pour la plateforme iOS."				
				}				
			]
		},
		{
			title : {
				en : "Programmer",
				fr : "Technicien Informatique"
			},
			place : {
				en : "Marguerite-Bourgeoys School Services Centre",
				fr : "Centre de services scolaire Marguerite-Bourgeoys"
			},
			range : {
				en : "November 2011 – March 2016",
				fr : "Novembre 2011 – Mars 2016"			
			},
			tasks : [
				{
					en : "Visual Basic.NET in-house applications maintenance on Windows or Microsoft IIS platforms.",
					fr : "Maintenance des applications maison Visual Basic.NET native Windows ou Web Microsoft IIS."				
				},
				{
					en : "Development of SSRS reports.",
					fr : "Élaboration de rapports SSRS."				
				},
				{
					en : "Creation of automated SSIS tasks for data conversion and management.",
					fr : "Création de tâches automatisées SSIS de gestion et de conversion de données."				
				},
				{
					en : "Developed and updated of transact-SQL (T-SQL) queries on SQL Server databases.",
					fr : "Construction et mise à jour de requêtes Transact-SQL (T-SQL) sur des bases de données SQL Server."				
				}				
			]
		},
		{
			title : {
				en : "IT Operator",
				fr : "Opérateur Informatique"
			},
			place : {
				en : "Marguerite-Bourgeoys School Services Centre",
				fr : "Centre de services scolaire Marguerite-Bourgeoys"
			},
			range : {
				en : "March 2011 – November 2011",
				fr : "Mars 2011 – Novembre 2011"			
			},
			tasks : [
				{
					en : "Troubleshooting and replacement of computer equipment.",
					fr : "Dépannage et remplacement de matériel informatique."				
				},
				{
					en : "Installation and maintenance of educational and administrative software for Windows environments.",
					fr : "Installation et maintenance des logiciels pédagogiques et administratifs sur les environnements Windows."				
				},
				{
					en : "IT support for teachers and administration under Windows XP and Windows 7 environments.",
					fr : "Soutien aux enseignants et au personnel administratif sous les environnements Windows XP et Windows 7."				
				},
				{
					en : "Network maintenance.",
					fr : "Entretien du réseau informatique des écoles supportées."				
				}				
			]
		},
		{
			title : {
				en : "IT Technician",
				fr : "Technicien Informatique"
			},
			place : {
				en : "Envitech Energy",
				fr : "Envitech Énergie"
			},
			range : {
				en : "May 2010 – September 2010",
				fr : "Mai 2010 – Septembre 2010"			
			},
			tasks : [
				{
					en : "Designing a new intranet for the company.",
					fr : "Conception d’un nouvel intranet pour l’entreprise."				
				},
				{
					en : "IT support under Windows 2000, XP and Windows 7 environments.",
					fr : "Soutien aux usagers sous les environnements Windows 2000, XP et Windows 7."				
				},
				{
					en : "Manage systems backup.",
					fr : "Gérer les systèmes de sauvegarde automatisés des données (Backup)."				
				},
				{
					en : "Server Maintenance (Windows Server 2000, 2003 R2 and 2008).",
					fr : "Maintenance des serveurs sous l’environnement Windows Server 2000, 2003 R2 et 2008."				
				},
				{
					en : "Creation and update of the documentation for IT systems.",
					fr : "Participation à la création et à la mise à jour de la documentation des systèmes informatiques."				
				}				
			]
		}			
	]
};

/**
 * Education section content.
 */
export const EDUCATION_SECTION = {
	title : {
		en : "Educa&shy;tion",
		fr : "Forma&shy;tion"
	},
	educations : [
		{
			isCertification : false,
			program : {
				en : "Bachelor of Computer Science by accumulated certificates (with distinction)",
				fr : "Baccalauréat en informatique par cumul de certificats (avec mention)"
			},
			place : {
				en : "Université du Québec À Montréal (UQAM)",
				fr : "Université du Québec À Montréal (UQÀM)"
			},
			range : {
				en : "2013 - 2020",
				fr : "2013 – 2020"			
			},
			programCode : {
				en : "C311",
				fr : "C311"					
			}
		},		
		{
			isCertification : true,
			program : {
				en : "Business technologies certification",
				fr : "Certificat en technologies d'affaires"
			},
			place : {
				en : "École des sciences de la gestion (ESG UQAM)",
				fr : "École des sciences de la gestion (ESG UQÀM)"
			},
			range : {
				en : "2018 - 2020",
				fr : "2018 – 2020"			
			},
			programCode : {
				en : "4728",
				fr : "4728"					
			}
		},
		{
			isCertification : true,
			program : {
				en : "Advanced Software development certification",
				fr : "Certificat avancé en développement de logiciels"
			},
			place : {
				en : "Université du Québec À Montréal (UQAM)",
				fr : "Université du Québec À Montréal (UQÀM)"
			},
			range : {
				en : "2016 - 2018",
				fr : "2016 – 2018"			
			},
			programCode : {
				en : "4626",
				fr : "4626"					
			}
		},
		{
			isCertification : true,
			program : {
				en : "Software development certification",
				fr : "Certificat en développement de logiciels"
			},
			place : {
				en : "Université du Québec À Montréal (UQAM)",
				fr : "Université du Québec À Montréal (UQÀM)"
			},
			range : {
				en : "2013 - 2016",
				fr : "2013 – 2016"			
			},
			programCode : {
				en : "4702",
				fr : "4702"					
			}
		},
		{
			program : {
				en : "College diploma in Computer Science",
				fr : "Diplôme d’études collégiales en informatique"
			},
			place : {
				en : "Gérald-Godin College",
				fr : "Cégep Gérald-Godin"
			},
			range : {
				en : "2007 - 2011",
				fr : "2007 - 2011"			
			},
			programCode : {
				en : "420.AA",
				fr : "420.AA"					
			}
		}		
	]
};

/**
 * Certification section content.
 */
export const CERTIFICATIONS_SECTION = {
	title : {
		en : "Certifi&shy;cations",
		fr : "Certifi&shy;cations"
	},
	certifications : [
		{
			issuerCode : {
				en : "AZ-900",
				fr : "AZ-900"
			},
			name : {
				en : "Microsoft Azure Fundamentals",
				fr : "Microsoft Azure Fundamentals"
			},
			range : {
				en : "December 2021",
				fr : "Décembre 2021"			
			}
		}	
	]
};

/**
 * Skill section content.
 */
export const SKILLS_SECTION = {
	title : {
		en : "Skills",
		fr : "Compé&shy;tences"
	},
	programmingSkill : {
		title : {
			en : "Programming Languages & Tools",
			fr : "Langages de programmation & Outils"				
		},
		items : [
			"HTML5 & XHTML",
			"CSS",
			"JavaScript",
            "Node.js",
			".NET 6 / 7 ",
            ".NET Framework - 2.0, 3.X, 4.X" ,
			"Git",
			"Azure DevOps",
			"Team Foundation Server - TFS 2013, 2015, 2017",
			"TypeScript",
			"PHP",
			"C & C++",
			"Visual Basic.NET",
			"PowerShell",
			"Java",
			"C#",
			"Swift",
			"NUnit / MsTest"
		]
	},	
	webSkill : {
		title : {
			en : "Web",
			fr : "Applications web"				
		},
		items : [
			"Bootstrap",
			"Vue.js",
			"jQuery",
			"Blazor",
			"ASP.NET Core",
			"ASP.NET Web API 2",
			"ASP.NET MVC 5",
			"ASP.NET Web Forms",
			"Microsoft SharePoint"
		]
	},
	databaseSkill : {
		title : {
			en : "Database",
			fr : "Base de données"				
		},
		items : [
			"SQL Server 2008, 2012, 2014, 2016, 2017, 2019, 2022",
			"SQL Server Reporting Services - SSRS",
			"SQL Server Integration Services - SSIS",
			"T-SQL",
			"MySQL",
			"MariaDB"
		]
	},
	osSkill : {
		title : {
			en : "Operating systems",
			fr : "Systèmes d'exploitation"				
		},
		items : [
			"Windows 98, ME, NT, 2000, XP, Vista, 7, 8.1, 10",
			"Windows Server 2003, 2008 (R2), 2012 (R2), 2016, 2019, 2022",
			"Ubuntu 16.x, 18.x, 20.x",
			"Mac OS X",
			"iOS",
			"Android"
		]
	}			
};