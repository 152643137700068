<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
        <a class="navbar-brand js-scroll-trigger" href="#about">
            <span class="d-block d-lg-none">
                <img class="img-fluid img-profile rounded-circle mx-auto" :src="profileImg" alt="Vincent Gagnon">
            </span>
            <span class="d-none d-lg-block">
                <img class="img-fluid img-profile rounded-circle mx-auto mb-2" :src="profileImg" alt="Vincent Gagnon">
            </span>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul id="navigation" class="navbar-nav">
                <li v-for="nav in navigationItems" :key="nav" class="nav-item">
                    <a class="nav-link js-scroll-trigger" v-bind:href="'#' + nav.href">{{ nav.text }}</a>
                </li>
                <hr class="language-separator" />
                <li class="nav-item dropdown language-selector">
                    <a class="nav-link dropdown-toggle nav-language-item" href="#languages" data-toggle="collapse"
                        aria-expanded="true">
                        <font-awesome-icon :icon="['fas', 'language']" />&nbsp;
                        <span id="currentLanguage">{{ currentLanguage.description }}</span>
                    </a>
                    <div class="collapse language-collapse">
                        <a v-for="lang in otherLanguages" :key="lang.id" class="nav-link language-item"
                            href="javascript:;" @click="switchLanguage(lang.id)" :data-language="lang.id">
                            {{ lang.description }}
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script setup>

    import { NAVIGATION_ITEMS, AVAILABLE_LANGUAGES } from '../data/side-nav-data'
    import { closeNavbarCollapse } from '../static/sidenav-action'
    import { getLocalizedString } from '../static/utilities'
    import commonProps from "../shared/shared-props"
    import { computed, defineProps, defineEmits } from 'vue'

    const props = defineProps({
        ...commonProps
    })

    const profileImg = require('../assets/profile.jpg');
    //const profileImg = require('../assets/thumbnail.png');
    const navigationItems = computed(() => NAVIGATION_ITEMS.map(item => ({
        text: getLocalizedString(item, props.languageId),
        href: item.sectionId
    })));

    const currentLanguage = computed(() => AVAILABLE_LANGUAGES.filter(l => l.id == props.languageId)[0]);

    const otherLanguages = computed(() => AVAILABLE_LANGUAGES.filter(l => l.id != props.languageId));

    const emit = defineEmits(['languageChanged'])

    const switchLanguage = function (languageId) {
        closeNavbarCollapse();
        emit('languageChanged', languageId);
    }
    
</script>