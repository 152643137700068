<template>
    <section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="experience">
        <div class="w-100">
            <ResumeSectionTitleComponent :languageId="props.languageId" :title="props.data.title" />

            <div v-for="(exp, index) in localizedExperiences" :key="index"
                class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="resume-content">
                    <h3 class="mb-0">{{ exp.title }}</h3>
                    <div class="subheading mb-3">{{ exp.place }}</div>
                    <ul class="list-experience">
                        <li v-for="(task, index) in exp.tasks" :key="index">{{ task }}</li>
                    </ul>
                </div>
                <div class="resume-date text-md-right">
                    <span class="text-primary">{{ exp.range }}</span>
                </div>
            </div>

        </div>
    </section>
</template>

<script setup>

import commonProps from '../shared/shared-props'
import { getLocalizedString, getLocalizedList } from '../static/utilities'
import ResumeSectionTitleComponent from './ResumeSectionTitleComponent.vue'
import { computed, defineProps } from 'vue'

const props = defineProps({
    ...commonProps
    , data: {
        type: Object,
        required: true
    }
})

const localizedExperiences = computed(() => {

    const experiences = [];

    for (const ele of props.data.experiences) {
        experiences.push({
            title: getLocalizedString(ele.title, props.languageId),
            place: getLocalizedString(ele.place, props.languageId),
            range: getLocalizedString(ele.range, props.languageId),
            tasks: getLocalizedList(ele.tasks, props.languageId)
        });
    }

    return experiences;
});

</script>