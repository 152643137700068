/**
 * Set the language code of the lang attribute of the html tag.
 * @param {string} languageId The language code to assign.
 */
export function setHtmlLanguage(languageId) {
    const html = document.documentElement;
    html.setAttribute('lang', languageId);
}

/**
 * Returns an array of strings in a specified language.e.
 * @param {Array} list The source array containing objects with the properties 'en' - 'fr' which represents the texts in each of the languages.
 * @param {string} language The language code.
 * @return {Array} An array containing the strings in the specified languages.
 */
export function getLocalizedList(list, language) {
    let localisedList = [];

    for (let i = 0; i < list.length; i++) {
        localisedList.push(getLocalizedString(list[i], language));
    }

    return localisedList;
}

/**
 * Returns a string in a specified language.
 * @param {object} value Object with the properties 'en' - 'fr' which represents the texts in each of the languages.
 * @param {string} language The language code.
 * @return {string} The string in the specified languages.
 */
export function getLocalizedString(value, language) {

    if (!value.fr || !value.en)
        return value;

    return language == 'fr' ? value.fr : value.en;
}

/**
 * Generate an array containing one or two arrays.
 * @param {Array} arr Source Array.
 * @param {number} breakCount The maximum number of elements before making two symmetric arrays (if the number of elements is even).
 * @return {Array} A two-dimensional array.
 */
export function splitArray(arr, breakCount) {

    let res = [];

    let arrayCount = arr.length > breakCount ? 2 : 1;
    let chunkLength = Math.max(Math.ceil(arr.length / arrayCount), 1);

    for (let i = 0; i < arrayCount; i++) {
        res.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
    }

    return res;
}