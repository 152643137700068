<template>
    <section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="certifications">
        <div class="w-100">
            <ResumeSectionTitleComponent :languageId="props.languageId" :title="props.data.title" />

            <div v-for="(cert, index) in localizedCertifications" :key="index"
                class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="resume-content">
                    <h4 class="mb-0">{{ cert.name }}</h4>
                    <div class="subheading mb-3">{{ cert.issuerCode }}</div>
                </div>
                <div class="resume-date text-md-right">
                    <span class="text-primary">{{ cert.range }}</span>
                </div>
            </div>

        </div>
    </section>
</template>

<script setup>

    import commonProps from '../shared/shared-props'
    import { getLocalizedString } from '../static/utilities'
    import ResumeSectionTitleComponent from './ResumeSectionTitleComponent.vue'
    import { computed, defineProps } from 'vue'

    const props = defineProps({
        ...commonProps
        , data: {
            type: Object,
            required: true
        }
    })

    const localizedCertifications = computed(() => {

        const certifications = [];

        for (const ele of props.data.certifications) {

            certifications.push({
                issuerCode: getLocalizedString(ele.issuerCode, props.languageId),
                name: getLocalizedString(ele.name, props.languageId),
                range: getLocalizedString(ele.range, props.languageId),
            });
        }

        return certifications;
    });

</script>