<template>
    <section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="education">
        <div class="w-100">
            <ResumeSectionTitleComponent :languageId="props.languageId" :title="props.data.title" />

            <div v-for="(educ, index) in localizedEducations" :key="index"
                class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="resume-content" :class="{ 'ml-5': educ.isCertification }">
                    <h3 class="mb-0" v-if="!educ.isCertification">{{ educ.place }}</h3>
                    <h4 class="mb-0" v-else>{{ educ.place }}</h4>
                    <div class="subheading mb-3" :class="{ 'certification': educ.isCertification }">{{ educ.program }}</div>
                    <p>Code: {{ educ.programCode }}</p>
                </div>
                <div class="resume-date text-md-right" :class="{ 'ml-5 ml-md-0': educ.isCertification }">
                    <span class="text-primary">{{ educ.range }}</span>
                </div>
            </div>

        </div>
    </section>
</template>

<script setup>

    import commonProps from '../shared/shared-props'
    import { getLocalizedString } from '../static/utilities'
    import ResumeSectionTitleComponent from './ResumeSectionTitleComponent.vue'
    import { computed, defineProps } from 'vue'

    const props = defineProps({
        ...commonProps
        , data: {
            type: Object,
            required: true
        }
    })

    const localizedEducations = computed(() => {

        const educations = [];

        for (const ele of props.data.educations) {
            educations.push({
                program: getLocalizedString(ele.program, props.languageId),
                place: getLocalizedString(ele.place, props.languageId),
                range: getLocalizedString(ele.range, props.languageId),
                programCode: getLocalizedString(ele.programCode, props.languageId),
                isCertification: ele.isCertification ? true : false
            });
        }

        return educations;
    });

</script>